<template>
  <div class="header-bot-sec">
    <div class="container">
      <div class="row">
        <form action="#">
          <p>
            <input type="radio" id="test1" name="radio-group" checked />
            <label for="test1"><span>QUOTE</span></label>
          </p>
          <p>
            <input type="radio" id="test2" name="radio-group" />
            <label for="test2"><span>YOUR INFO</span></label>
          </p>
          <p>
            <input type="radio" id="test3" name="radio-group" />
            <label for="test3"><span>PAYMENT</span></label>
          </p>
          <p>
            <input type="radio" id="test4" name="radio-group" />
            <label for="test4"><span>WELCOME</span></label>
          </p>
        </form>
      </div>
    </div>
  </div>
  <div class="Monthly-Head">
    <div class="container">
      <div class="row">
        <p>Monthly Quote Total: $xx.xx <span>(taxes extra)</span></p>
      </div>
    </div>
  </div>
</template>
